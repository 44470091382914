import React from 'react'

//style
import "../../style/main.scss"
import "../../style/sectionsStyle/section4.scss"


//imgs
import img1 from '../../style/imgs/img1.png'
import img2 from '../../style/imgs/img2.png'
import img3 from '../../style/imgs/img3.png'

function section4() {
  return (
        
    <section className='section4'>
    <div className="why1 whyus">
      <img src={img1} alt="SovaCinema Клинт" />
      <div className="text">
        <h2>КИНОТЕАТР ДЛЯ ДВОИХ</h2>
        <p>SovaCinema это место, которое поможет тебе выбрать идеальный подарок для своей половинки, свечи, лепестки роз, фильм и только вы вдвоем. Свечи ручной работы, куча лепестков, дизайнерский зал, профессиональная акустика, экран 5 метров – все это для вашего свидания. Мы даем гарантию!</p>
      </div>
    </div>

    <div className="reverse whyus">
      <img src={img2} alt="SovaCinema Клинт" />
      <div className="text">
        <h2>Красиво признаться в любви!</h2>
        <p>Нужна идея, как признаться в любви? Это по нашей части! У нас есть масса сценариев предложения, огромный опыт и всё необходимое! Только Вы вдвоем в частном кинозале: большой экран, удобный диван, лепестки роз и свечи - идеальная обстановка для предложения руки и сердца! Устроив сюрприз в нашем кинозале, Вы удивите и порадуете свою половинку. Это оригинальный и запоминающийся подарок, который не оставит равнодушным никого!</p>
      </div>
    </div>

    <div className="why3  whyus">
      <img src={img3} alt="SovaCinema Клинт" />
      <div className="text">
        <h2>Отличный подарок для мужчины и женщины!</h2>
        <p>Если хотите сделать действительно красивый, необычный подарок, то свидание в кино - это то, что нужно. Вырваться из серых будней, окунуться в атмосферу уединения и любви - вот что действительно крутой подарок! Подарить любимому или любимой заботу, тепло и уют помогут Вам наши специалисты с многолетним опытом проведения свиданий! Частный приватный кинозал сова создан именно для этого.</p>
      </div>
    </div>
  
   </section>
    )
}

export default section4